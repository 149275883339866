@media (width <= 50em) {
  .container {
    width: 57rem;
  }

  .header {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    gap: 1rem;
    display: grid;
  }

  .header-title {
    column-span: all;
    display: flex;
  }

  .config-form {
    grid-row: 2 / 3;
  }

  .reset-btn {
    grid-row: 2 / 3;
    margin-right: 2rem;
  }

  .btn {
    width: -moz-fit-content;
    width: fit-content;
  }

  .schedule-section {
    max-width: 38rem;
  }

  .daily-schedule-form {
    grid-template-columns: 8rem 30rem;
  }

  .schedule-input {
    width: 30rem;
  }
}

@media (width <= 38em) {
  .container {
    width: 100%;
    padding: 0 2rem;
  }

  .header, .config-form {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .config-form-select {
    margin-top: 1rem;
    font-size: 2rem;
  }

  .reset-btn {
    margin: 0 auto;
  }

  .btn {
    font-size: 2rem;
  }

  .schedule-section {
    max-width: 100%;
    margin-top: 23rem;
  }

  .daily-schedule-form {
    grid-template-columns: 6rem 1fr;
  }

  .schedule-input {
    width: 100%;
  }
}

/*# sourceMappingURL=index.d6c11f5d.css.map */
